<template>
  <div class="optional-steps__body">
    <ui-optional-step
      v-for="(step, i) in steps"
      :key="$basil.uniqId(i)"
      :value="step"
    />

    <actions-button
      @click="onConfirm"
      :appearance="$pepper.Appearance.PRIMARY"
      :size="$pepper.Size.L"
      class="optional-steps__action"
    >{{ $t('conn3ct-wallet.continue') }}</actions-button>
  </div>
</template>

<script>
import onboardingSteps from '@/data/onboarding'
import UiOptionalStep from '@/components/ui/optional-step.vue'

export default {
  name: 'OnboardingOptionalSteps',

  components: {
    UiOptionalStep,
  },

  data: () => {
    return {
      showModal: false
    }
  },

  computed: {
    steps() {
      let identifier = this.$user.user.registrationIdentifier

      if(!onboardingSteps?.length) return []

      return onboardingSteps
        .filter(s => (!s.isMandatory && (this.$basil.isNil(s.identifier) || s.identifier === identifier)))
        .sort((a, b) => a.order - b.order)
    },

    importantStepsSkipped() {
      return this.steps.filter(s => s.isImportant && !this.$user.user[s.flag])
    }
  },

  methods: {
    onConfirm() {
      this.$emit('next')
    },
  },

  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>
