<template>
  <button
    @click="onClick"
    class="ui-optional-step"
    :class="{
      '-is-done': isDone
    }"
  >
    <div>
      <h5 class="ui-optional-step__title">
        <span>{{ title }}</span>

        <div
          v-if="isDone"
          class="ui-optional-step__status -is-done"
        >
          <ui-icon glyph="check" />
        </div>

        <div
          v-if="isImportant && !isDone"
          class="ui-optional-step__status -is-important"
        >
          <ui-icon glyph="exclamation" />
        </div>
      </h5>
      <span class="ui-optional-step__description">{{ description }}</span>
    </div>

    <ui-icon glyph="arrow-right" />
  </button>
</template>

<script>
export default {
  name: 'UiOptionalStep',

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  computed: {
    title() {
      return this.$t(`conn3ct-wallet.onboarding_step_${ this.value.name }_title`)
    },

    description() {
      return this.$t(`conn3ct-wallet.onboarding_step_${ this.value.name }_description`)
    },

    isDone() {
      return this.$user.user[this.value.flag] ?? false
    },

    isImportant() {
      return this.value.isImportant ?? false
    }
  },

  methods: {
    onClick() {
      if(this.isDone) return

      this.$router.replace({ name: this.value.route, params: { isOptional: true } }).catch(() => {})
    }
  },
}
</script>
